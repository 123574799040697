<template>
  <div
    class="section-shaped mt-4 mb-0 section section-skew black-gradient-header"
  >
    <div class="container black-gradient-header card mb-0" v-if="user">
      <div class="row">
        <router-link slot="title" class="btn-link p-2" :to="{ name: 'main' }">
          <i class="ni ni-bold-left"></i> <span>Back to Home</span>
        </router-link>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="section">
            <!-- User Information -->
            <section class="text-center">
              <i class="ni ni-circle-08 text-6xl text-dark"></i>
              <h3 class="title mt-4">{{ user.name }}</h3>
              <template v-if="user.subscription.status === 'trialing'">
                <span class="badge text-capitalize badge-primary"
                  >Trial ends at:
                  <strong>{{
                    user.subscription.trial_ends_at | moment("MMMM Do")
                  }}</strong>
                </span>
              </template>
              <template v-else-if="user.subscription.status === 'canceled'">
                <span class="badge text-capitalize badge-danger"
                  >Cancelled account</span
                >
                <span class="badge text-capitalize text-danger">
                  you will have access until
                  <strong>{{
                    user.subscription.trial_ends_at | moment("MMMM Do")
                  }}</strong>
                </span>
              </template>
              <template v-else>
                <span class="badge text-capitalize badge-primary"
                  >Next renew date:
                  <strong>{{
                    user.subscription.ends_at | moment("MMMM Do")
                  }}</strong>
                </span>
              </template>
            </section>
            <section class="nav-wrapper">
              <a
                @click="accountTab = 'General'"
                class="btn btn-1 btn-block btn-outline-primary"
                v-bind:class="{ active: accountTab === 'General' }"
              >
                General
              </a>
              <a
                class="btn btn-1 btn-block btn-outline-primary"
                @click="manageSubscription()"
              >
                Plans & Billing Information
              </a>
              <div class="row d-block d-md-none" v-if="isCanceled">
                <div class="col">
                  <span class="font-weight-bold">
                    To reactivate your account please click or tap on "Plans &
                    Billing information"
                  </span>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="col-md-8 ml-auto">
          <div class="section d-none d-md-block">
            <div class="tab-content">
              <div v-if="accountTab === 'General'" class="tab-pane active">
                <div>
                  <header>
                    <h2 class="text-uppercase">General information</h2>
                  </header>
                  <hr class="line-primary" />
                  <br />
                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels">Name</label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      {{ user.name }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels">E-Mail</label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      {{ user.email }}
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="user.subscription.status === 'canceled'"
                  >
                    <div class="col mt-5">
                      <span class="font-weight-bold"
                        >To reactivate your account please click or tap on
                        "Plans & Billing information"</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="accountTab === 'Billing'" class="tab-pane active">
                <header>
                  <h2 class="text-uppercase">Billing information</h2>
                </header>
                <hr class="line-primary" />

                <div class="row">
                  <div class="col-md-3 align-self-center">
                    <label class="labels"><strong>Subscription</strong></label>
                  </div>
                  <div class="col-md-9 align-self-center">
                    <span class="badge badge-info text-capitalize">
                      {{
                        user.subscription.gateway_plan.subscription_plan.price
                          .formatted
                      }}
                      /
                      {{
                        user.subscription.gateway_plan.subscription_plan
                          .interval
                      }}
                    </span>
                    <h3>
                      {{
                        user.subscription.gateway_plan.subscription_plan.name
                      }}
                    </h3>
                    <p>
                      {{
                        user.subscription.gateway_plan.subscription_plan
                          .description
                      }}
                    </p>
                    <template
                      v-if="
                        ![
                          'incomplete',
                          'incomplete_expired',
                          'canceled',
                        ].includes(user.subscription.status)
                      "
                    >
                      <button
                        type="button"
                        v-if="user.subscription.payment_gateway_subscription_id"
                        class="btn btn-sm btn-outline-danger d-block text-light"
                        @click="modals.cancel = true"
                      >
                        Cancel Subscription
                      </button>
                      <template>
                        <div class="col-md-4">
                          <modal
                            :show.sync="modals.cancel"
                            modal-classes="modal-danger modal-dialog-centered"
                          >
                            <h6
                              slot="header"
                              class="modal-title"
                              id="modal-title-notification"
                            >
                              Your attention is required
                            </h6>

                            <div class="py-3 text-center">
                              <i
                                class="fa fa-exclamation-triangle text-5xl"
                              ></i>
                              <h4 class="heading mt-4">
                                Are you sure do you want to <br />
                                cancel your subscription?
                              </h4>
                              <hr class="border-bottom-dashed m-3" />
                              <p>
                                If you choose to cancel, your subscription will
                                continue to work until the date when the next
                                payment would have been due.
                              </p>
                            </div>

                            <template slot="footer">
                              <base-button
                                id="cancel_subscription_button"
                                type="outline-white"
                                @click="cancelSubscription()"
                                >Cancel subscription
                              </base-button>
                              <base-button
                                type="white"
                                class="ml-auto"
                                @click="modals.cancel = false"
                                >Nevermind
                              </base-button>
                            </template>
                          </modal>
                        </div>
                      </template>
                    </template>
                  </div>
                  <div class="col-11 mt-3" v-if="isCanceled">
                    <p class="small">
                      <strong>Cancel your subscription?</strong> <br />
                      Click "Cancel Subscription" below to cancel your
                      subscription. <br />

                      You can continue to watch content on Bujin.TV for the
                      remainder of your current billing period.
                      <br />
                      Restart your subscription at any time. No penalty or
                      cancellation fees apply.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  bodyClass: "account-settings",
  components: {},
  created() {
    this.redirectToLogIn();
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
    isCanceled() {
      return this.user.subscription.status === "canceled";
    },
  },
  methods: {
    redirectToLogIn() {
      if (this.user === null) {
        console.log("3PX: Redirect ", this.user);
        this.$router.push("login");
      }
    },
    onImageChange(file) {
      this.images.regular = file;
    },
    onAvatarChange(file) {
      this.images.avatar = file;
    },
    manageSubscription() {
      axios.get("/me/stripe").then((response) => {
        let url = response.data.url;
        if (typeof url !== "undefined") {
          window.location.href = url;
        } else {
          alert("Unable to get customer info");
        }
      });
    },
    cancelSubscription() {
      this.klaviyoCancelAccount();
      axios.get("/subscriptions/cancel", {}).then(() => {
        // console.log(response);
        this.$store.dispatch("fetchProfile").then(() => {
          this.modals.cancel = false;
        });

        // this.$router.push({ name: "main" });
      });
    },
  },
  data() {
    return {
      accountTab: "General",
      modals: {
        cancel: false,
      },
      selected: 2,
      switches: {
        off: false,
        on: true,
      },
      radio: {
        radio1: "radio1",
      },
    };
  },
};
</script>
